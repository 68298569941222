import React from "react"
import styled from "@emotion/styled"

import Layout from "../components/Layout"
import Header from "../components/Header"

import jb from "../images/jamie-brannan.jpg"


const PageContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    margin-top: 8vh;
    height: 100vh;

    @media (max-width: 420px) {
      flex-direction: column-reverse;
    }
`;

const ContentColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 60%;

    @media (max-width: 420px) {
      width: 100%;
    }
`;

const Bodytext = styled.div`
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 1em;

    width: 100%;

    letter-spacing: 0.04em;
    line-height: 1.6em;
    color: #000;

    @media max-width(420px){
      width: 100%;
    }
`;

const ImageColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-end;
    
    width: 56%;

    @media (max-width: 420px) {
      height: 100%;
      width: 100%;
      padding-bottom: 2em;
    }
`;

const ProfileImage = styled.div`
  background: url(${jb});
  background-position: center;
  background-image: cover;
  background-repeat: no-repeat;
  background-size: contain;

  align-self: flex-end;
  height: 65%;
  width: 65%;

  @media (max-width: 420px) {
    align-self: center;
    height: 100%;
    width: 100%;
  }
`;
// const WorkMosaic = styled.div`
//     display: flex;
//     flex-direction: column;
//     justify-content: center;

//     width: (100%/2);
//     height: 40vh;

//     background:url();
// `;

export default () => (
  <Layout>
    <PageContent>
      <ContentColumn>
        <Header />
        <Bodytext>
          Creating digital products that answer your project’s needs purposefully is my mission; delivering solutions via detailed research methods and digital expertise, my specialty.<br/><br/>
          A Bay-Area native and now seasoned expat, you can find me wandering the museums of Paris and the stacks of the libraries in my free time.
        </Bodytext>
      </ContentColumn>
      <ImageColumn>
        <ProfileImage/>
      </ImageColumn>
    </PageContent>
  </Layout>
)