import React from "react"
import styled from "@emotion/styled"

const Subtitle = styled.h2`
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.5em;
    
    letter-spacing: 1.8px;
    color: #ff6666;

    font-style: normal;
    font-stretch: normal;
    line-height: normal;

    margin: 0;
    @media (max-width: 420px){
        font-size: 1.3em;
    }
`;

const Title = styled.h1`
    font-family: 'Poppins';
    font-weight: 500;

    color: #003399;

    font-size: 2.7em;
    letter-spacing: 1px;

    margin: 8vh 0 4vh 0;

    @media (max-width: 420px){
        font-size: 2.4em;
        margin: 24px 0 24px 0;
    }
`;

export default () => {
    
    return(
        <div>
            <Subtitle>iOS DEVELOPER</Subtitle>
            <Title>Let's join forces</Title>
        </div>
    )
}